.main {
    background-color: white;
    height: 100%;
}

header {
    display: flex;
    justify-content: center;
    background-color: rgb(35, 35, 107)
}

h1 {
    color: white;
}

.headerLink {
    text-decoration: none;
}