.noteAlign {
    display: flex;
    justify-content: center;
}

.noteLink {
    color: white;
    text-decoration: none;
}

.noteLink h2 {
    font-size: 40px;
}

.noteContainer {
    text-align: center;
    background-color: rgb(21, 21, 73);
    width: 500px;
    margin-top: 100px;
    color: white;
}

.btn-noteBack {
    margin-top: 100px;
}

.btn-noteDelete {
    margin-bottom: 30px;
    padding: 10px;
}

.noteContent {
    width: 300px;
}

.contentContainer {
    display: flex;
    justify-content: center;
}